// GALLERY MODAL
import { useEffect, useRef } from "react"

export default function useGallery() {
  let allTheImages = useRef(null)

  useEffect(() => {
    if (document.querySelectorAll(".gallery .col")) {
      allTheImages.current = document.querySelectorAll(".gallery .col")
      addLinkListener(allTheImages)
    }
    return () => {}
  }, [])

  const addLinkListener = allTheImages => {
    allTheImages.current.forEach(li => {
      //GALERY OPEN
      li.addEventListener("click", e => {
        e.stopPropagation()
        //remove open if they have
        removeOpen(allTheImages.current)
        //add open
        setTimeout(() => li.classList.toggle("open"), 0.5)

        li.addEventListener("click", () => li.classList.toggle("open"))
      })

      document.addEventListener("keydown", function (event) {
        if (event.key === "Escape") {
          li.classList.remove("open")
        }
      })
    })
  }
  const removeOpen = allTheImages => {
    allTheImages.forEach(li => li.classList.remove("open"))
  }
}
