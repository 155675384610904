import React from "react"
import { AnimationOnScroll } from "react-animation-on-scroll"
import Skillprogressbar from "../Skillprogressbar"

export default function index({ skillsTitle, skills }) {
  return (
    <section
      id="skills"
      className=" slide-in d-flex flex-wrap col-12  text-white "
    >
      <div className="container d-flex align-items-center  justify-content-center d-flex flex-column flex-md-row">
        <div className="col-12 col-md-6  m-0 p-2 d-flex align-items-center ">
          <AnimationOnScroll animateIn="animate__fadeInLeftBig">
            <div
              className="col-12 "
              dangerouslySetInnerHTML={{
                __html: `<div  >${skillsTitle}</div>`,
              }}
            ></div>
          </AnimationOnScroll>
        </div>

        <AnimationOnScroll
          className=" col-12 col-md-5   mx-auto p-2  d-flex align-items-center gap-3 justify-content-center d-flex flex-column"
          animateIn="animate__tada"
        >
          <Skillprogressbar skills={skills} />
        </AnimationOnScroll>
      </div>
    </section>
  )
}
