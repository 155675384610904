import React from "react"
import { useStaticQuery, graphql, Script } from "gatsby"
import Layout from "../components/layout"
import "bootstrap-icons/font/bootstrap-icons.css"
import SectionAbout from "../components/SectionAbout"
import SectionSkills from "../components/SectionSkills"
import SectionWork from "../components/SectionWork"
import SectionGetIntouch from "../components/SectionGetIntouch"
import { Seo } from "../components/Seo"
import useGallery from "../hooks/useGallery"

export default function IndexPage() {
  const indexQuery = useStaticQuery(
    graphql`
      query AnotherPage {
        ...IndexPageData
      }
    `
  )
  const {
    aboutText,
    aboutImg,
    aboutTecnologiesItems,
    gallerySection,
    contactMe,
    skills,
    skillsTitle,
  } = indexQuery.wpPage.acf_index

  const scripts = {
    bootstrapJS: "/js/bootstrap.min.js",
    popperJs: "/js/popper.min.js",
    TagCloudJs: "/js/TagCloud.min.js",
  }

  //Interaction with the gallery click handle
  useGallery()

  return (
    <>
      <Layout>
        <SectionAbout
          aboutImg={aboutImg}
          aboutText={aboutText}
          aboutTecnologiesItems={aboutTecnologiesItems}
        />
        {/* SKILLS  Esta bien pero tiene problema de font */}
        <SectionSkills skills={skills} skillsTitle={skillsTitle} />

        {/* WORK */}
        <SectionWork gallerySection={gallerySection} />

        {/* GETINTOUCH could be map*/}
        <SectionGetIntouch contactMe={contactMe} />
      </Layout>

      <Script
        src={scripts.bootstrapJS}
        strategy="post-hydrate"
        onLoad={() => console.log(`SCRIPT LOADED ${scripts.bootstrapJS}`)}
        onError={() => console.log(`SCRIPT Error ${scripts.bootstrapJS}`)}
      />
      <Script
        src={scripts.popperJs}
        strategy="post-hydrate"
        onLoad={() => console.log(`SCRIPT LOADED ${scripts.bootstrapJS}`)}
        onError={() => console.log(`SCRIPT Error ${scripts.bootstrapJS}`)}
      />
      <Script
        src={scripts.TagCloudJs}
        strategy="post-hydrate"
        onLoad={() => console.log(`SCRIPT LOADED ${scripts.bootstrapJS}`)}
        onError={() => console.log(`SCRIPT Error ${scripts.bootstrapJS}`)}
      />
    </>
  )
}
export const Head = pageContext => {
  const indexQuery = useStaticQuery(
    graphql`
      query AnotherPage {
        ...IndexPageData
      }
    `
  )
  const { seoTitle, seoDescription } = indexQuery.wpPage.acf_index

  return <Seo title={seoTitle} description={seoDescription} />
}
