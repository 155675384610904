import React from "react"
import ImgRender from "./ImgRender"

export default function GallerySections({ galleryCat }) {
  if (galleryCat)
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide carousel-dark "
        // data-bs-ride="carousel"
      >
        <div className="container">
          <div className="arrow-work col-12 mb-5 mb-md-0 ">
            <div className="col-12 col-md-6  ms-auto position-relative">
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>

              <div className="carousel-indicators">
                {galleryCat.map((galleryCat, i) => (
                  <button
                    type="button"
                    key={i}
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={`${i}`}
                    className="active"
                    aria-label={`Slide ${i + 1}`}
                  ></button>
                ))}
              </div>
            </div>
          </div>

          <div className="carousel-inner mt-5 mt-md-0">
            {galleryCat.map((galleryCat, i) => (
              <div
                key={i}
                className={`carousel-item ${i === 0 ? "active" : " "}`}
              >
                <div
                  className="col-12 carusel--description d-flex flex-wrap align-items-center justify-content-center"
                  dangerouslySetInnerHTML={{
                    __html: `<div>${galleryCat.galleryTitle}</div>`,
                  }}
                />

                <div className="gallery row row-cols-1  row-cols-md-3">
                  {galleryCat.workGalleryImgs.map((photo, i) => (
                    <div
                      className="col text-center m-0 p-0 green"
                      id={`iamge${i}`}
                      key={i}
                    >
                      <figure className="wp-block-image size-full">
                        <ImgRender image={photo} />
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: photo.description,
                          }}
                        />
                      </figure>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
}
