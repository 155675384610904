import React from "react"
import GallerySections from "../GallerySections"

export default function index({ gallerySection }) {
  return (
    <section
      aria-label="work examples"
      id="work"
      className="slide-in d-flex flex-wrap col-11 m-auto col-md-12 pe-md-5 py-5  "
    >
      <div className=" m-auto d-flex  justify-content-center align-items-start py-md-5 my-md-5 ">
        {gallerySection ? <GallerySections galleryCat={gallerySection} /> : ""}
      </div>
    </section>
  )
}
