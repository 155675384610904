import React, { useEffect, useMemo } from "react"
import TagCloud from "TagCloud"

const Tagclouditems = ({ words }) => {
  const container = ".content"
  let divTag = null // Initialize divTag as null

  if (typeof window !== "undefined") {
    // Check if we are on the client-side
    divTag = document.querySelector(".tagWordDiv")
  }

  const fallBackTxt = useMemo(() => {
    return [
      "NextJs",
      "Gatsby",
      "JavaScript",
      "CSS3",
      "Animation",
      "Interactive",
      "Php",
      "Wordpress",
      "GraphQl",
      "Git",
      "Gulp",
    ]
  }, [])

  const myTags = useMemo(() => {
    return words.length > 2 ? words : fallBackTxt
  }, [words])

  const options = useMemo(() => {
    return {
      radius: 250,
      maxSpeed: "normal",
      initSpeed: "normal",
      direction: 135,
      keep: true,
    }
  }, [])

  useEffect(() => {
    if (!divTag) {
      console.log("NO HAY words tags")
      TagCloud(container, myTags, options, fallBackTxt)
    }
    //TagCloud(container, myTags, options)
  }, [myTags, options, divTag])

  //TagCloud(container, myTags, options)

  return <div className="content tagWordDiv"></div>
}

export default Tagclouditems
