import React from "react"
import Map from "../Map"
import { AnimationOnScroll } from "react-animation-on-scroll"

export default function index({ contactMe }) {
  return (
    <section id="getIntouch" className=" ">
      <div className="d-flex flex-wrap h-100vh">
        <div className="col-10 col-md-6  m-auto m-md-0  d-flex justify-content-center align-content-center align-items-center ">
          <AnimationOnScroll
            className=" d-flex justify-content-center col-10 align-items-center r"
            animateIn="animate__fadeInLeftBig"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `<div  tabIndex= "0" className="col-10 m-auto mt-5">
              ${contactMe}
      
      <form 
      name="contact" 
      method="POST" 
      data-netlify="true"
      >
              
              <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input id="exampleInputEmail1" class="form-control" name="email" required="required" type="email" aria-describedby="emailHelp" />
              </div>

              <div class="form-group">
              <label for="exampleInputName">Name</label>
              <input id="exampleInputName" class="form-control" name="name" required="required" type="text" />
              </div>

              <div class="form-group">
              <label for="w3review">Message</label>
              <textarea id="w3review" class="form-control" cols="50" name="message" required="required" rows="4" placeholder="Enter your message">Have any questions? Drop me a line...</textarea>
              </div>
          
            <p>
              <button class="btn btn-primary"  type="submit">Send</button>
            </p>
      </form>

              </div>`,
              }}
            ></div>
          </AnimationOnScroll>
        </div>
        {/* This map is costing too much in performance */}
        {/* <Map /> */}
        <img
          className="map"
          src="/images/map.png"
          width={594}
          height={1185}
          alt="global map wordpress developer at costa rica"
        />
      </div>
    </section>
  )
}
