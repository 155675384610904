import React from "react"

const Skillprogressbar = ({ skills }) => {
  return skills.map((skill, i) => (
    <div className="progress  col-12 " key={i}>
      <div
        className="progress-bar"
        role="progressbar"
        aria-label={skill.skillName}
        style={{
          width: `${skill.skillPorcentage}%`,
          backgroundColor: skill.skillColor,
        }}
      >
        {skill.skillName}
      </div>
    </div>
  ))
}

export default Skillprogressbar
