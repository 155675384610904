import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function ImgRender(data) {
  const { altText, gatsbyImage } = data.image
  const { width, height } = gatsbyImage

  return (
    <GatsbyImage
      image={gatsbyImage}
      width={width}
      height={height}
      rel="preload"
      loading="eager"
      layout="constrained"
      objectFit="cover"
      alt={altText || "WordPress developer"}
    />
  )
}
