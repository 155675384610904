import React, { useLayoutEffect } from "react"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4maps from "@amcharts/amcharts4/maps"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow"

am4core.useTheme(am4themes_animated)

const Map = () => {
  // MAP
  // Create map instance

  useLayoutEffect(() => {
    // Create map instance
    var chart = am4core.create("countryMap", am4maps.MapChart)

    // Set map definition
    chart.geodata = am4geodata_worldLow
    chart.chartContainer.wheelable = false
    // Set projection
    chart.projection = new am4maps.projections.Miller()

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true

    // Configure MAP
    var polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = "No here"
    polygonTemplate.fill = am4core.color("#492e68")
    polygonTemplate.stroke = am4core.color("#301450")
    polygonTemplate.nonScaling = false
    polygonTemplate.useGeodata = true // Make map load polygon (like country names) data from GeoJSON
    //polygonTemplate.scale = 1.1

    // Add some data
    polygonSeries.data = [
      {
        id: "CR",
        name: "Costa Rica",
        value: 100,
        fill: am4core.color("#04d0c7"),
      },
    ]
    // Remove Antarctica
    polygonSeries.exclude = ["AQ"]

    // Bind "fill" property to "fill" key in data
    polygonTemplate.propertyFields.fill = "fill"

    //ZOOM COUNTRY
    chart.events.on("inited", function () {
      chart.zoomToGeoPoint(
        {
          latitude: 9.748917,
          longitude: -83.753428,
        },
        50,
        true,
        50
      )
    })

    /* MAKE THE CIRLCE AND COLOR FOR CIRCLE */
    var imageSeries = chart.series.push(new am4maps.MapImageSeries())
    var imageSeriesTemplate = imageSeries.mapImages.template
    var circle = imageSeriesTemplate.createChild(am4core.Circle)
    circle.radius = 3.5
    circle.fill = am4core.color("#00000022")
    circle.stroke = am4core.color("#04d0c7")
    circle.strokeWidth = 0.2
    circle.nonScaling = false
    circle.tooltipText = `[bold]Costa Rica[/]
    _______
    Im here 😉
    email:👋  hi@mariolafuente.com
    `

    // Set property fields
    imageSeriesTemplate.propertyFields.latitude = "latitude"
    imageSeriesTemplate.propertyFields.longitude = "longitude"

    imageSeries.data = [
      {
        latitude: 9.748917,
        longitude: -83.753428,
        title: " ",
      },
    ]

    return () => {
      chart.dispose()
    }
  }, [])

  return (
    <div className="mapImg col-12 col-md-6 m-0   d-flex align-items-center  justify-content-center d-flex flex-wrap flex-column flex-md-row">
      <div id="countryMap" style={{ width: "100%", height: "100%" }}></div>
    </div>
  )
}

export default Map
