import React from "react"
import ImgRender from "../ImgRender"
import { AnimationOnScroll } from "react-animation-on-scroll"
import Tagclouditems from "../TagCloudItems"

export default function index({ aboutImg, aboutText, aboutTecnologiesItems }) {
  //TECHNOLY
  const tecnologyWords = aboutTecnologiesItems.toString().split(",")

  return (
    <section id="about" className="m-md-0 p-md-0 mt-5 pt-5 position-relative">
      <div className="container ">
        <div className="about_img m-md-0 p-md-0 h-100">
          <ImgRender image={aboutImg} />
        </div>
        <div className="about_content col-12 col-md-6 ms-md-auto  d-flex flex-column align-items-center justify-content-start justify-content-md-center">
          <AnimationOnScroll animateIn="animate__fadeInLeftBig">
            <div
              className="aboutText col-12 text-purple"
              dangerouslySetInnerHTML={{
                __html: `<section>${aboutText}</section>`,
              }}
            ></div>
          </AnimationOnScroll>
          <Tagclouditems words={tecnologyWords} />
        </div>
      </div>
    </section>
  )
}
